

import { Modal } from "bootstrap";
$(function () {
   // Home Suggession //
   if ($(".searchsuggestion").length) {
      $(".searchsuggestion").autocomplete({
         source: function (request, response) {
            getAutoSuggestion(request, response);
         }
      });
   }

   function getAutoSuggestion(request, response) {
      var suggesions = [];
      $.ajax({
         type: "get",
         url: $("#searchform").attr("data-href"),
         dataType: "json",
         data: { q: request.term },
         success: function (res) {
            $.each(res.data, function (k, v) {
               suggesions.push(v);
            });
            return response(suggesions);
         }
      });
   }

   // End Home suggestion

   //Start Video Page


   var played = false;
   $("#myPlayer").on("play", function () {
      if (!played) {
         let url = $("#myPlayer").attr('data-action');
         apiClient(url, {}, function (data) {
         });
         played = true;
      }
   });


   if ($(".v-options").length) {
      $(".v-options").on("click", ".like", function (e) {
         e.preventDefault();
         let data = {};

         if ($(this).hasClass("active")) {
            data.like = true;
            return;
         } else {
            data.like = false;
         }

         $(this).toggleClass("active");
         if ($(".v-options .unlike").hasClass("active")) {
            $(".v-options .unlike").removeClass("active");
            storeLikes($(this).attr("data-id"), "unlikes");
            data.unlike = true;
         } else {
            data.unlike = false;
         }
         storeLikes($(this).attr("data-id"), "likes");
         apiClient($(this).attr("data-href"), data, function (data) {
            $(".v-options .like").find(".title").text(data.likes);
         });
      });

      $(".v-options").on("click", ".unlike", function (e) {
         e.preventDefault();
         let data = {};

         if ($(this).hasClass("active")) {
            data.unlike = true;
            return;
         } else {
            data.unlike = false;
         }
         $(this).toggleClass("active");
         if ($(".v-options .like").hasClass("active")) {
            $(".v-options .like").removeClass("active");
            data.like = true;
            storeLikes($(this).attr("data-id"), "likes");
         } else {
            data.like = false;
         }
         storeLikes($(this).attr("data-id"), "unlikes");
         apiClient($(this).attr("data-href"), data, function (data) {
            $(".v-options .like").find(".title").text(data.likes);
         });
      });

      if (checkLikeUnlikes("likes")) {
         $(".v-options .like").addClass("active");
      } else if (checkLikeUnlikes("unlikes")) {
         $(".v-options .unlike").addClass("active");
      }
   }

   function checkLikeUnlikes(storage) {
      if (typeof (Storage) !== "undefined") {
         let id = $(".v-options").find(".like").attr("data-id");
         let likes = localStorage.getItem(storage);
         if (likes) {
            let likesArr = JSON.parse(likes);
            if (likesArr.indexOf(id) > -1) {
               return true;
            }
         }
      }
      return false;
   }

   function apiClient(url, postdata, callback) {
      postdata._token = $('meta[name="csrf-token"]').attr('content');
      $.ajax({
         url: url,
         type: "post",
         dataType: "json",
         data: postdata,
      }).done(function (res) {
         if (res.status == true) {
            console.log("successfuly request");
            callback(res.data);
         }
      }).fail(function () {
         console.log("Request failed");
      });
   }

   function storeLikes(likeId, storage) {
      if (typeof (Storage) !== "undefined") {
         let newLikes = [];
         let likes = localStorage.getItem(storage);
         if (likes) {
            let oldLikes = JSON.parse(likes);
            let index = oldLikes.indexOf(likeId);
            if (index > -1) {
               oldLikes.splice(index, 1);
            } else {
               oldLikes.push(likeId);
            }
            newLikes = oldLikes;
         } else {
            newLikes.push(likeId);
         }
         console.log(JSON.stringify(newLikes));
         localStorage.setItem(storage, JSON.stringify(newLikes));
      } else {
         console.log("LocalStorage not suppored");
      }
   }

   $(".show-more-button").on("click", function (e) {
      e.preventDefault();
      $(this).toggleClass("active");
      $(".show-more").toggleClass("visible");
      if ($(".show-more").is(".visible")) {
         let el = $(".show-more"),
            curHeight = el.height(),
            autoHeight = el.css("height", "auto").height();
         el.height(curHeight).animate({ height: autoHeight, }, 400);
      } else {
         $(".show-more").animate({ height: "50", }, 400);
      }
   });


   function sharePopup(u, t = "Share", x = 840, y = 600) {
      let uri = u.replace("(url)", window.location.href).replace("(title)", document.title);
      let n = screen.width / 2 - x / 2;
      let i = screen.height / 2 - y / 2;
      window.open(
         uri,
         "social",
         "width=" +
         x +
         ",height=" +
         y +
         ",menubar=0,toolbar=0,scrollbars=0,left=" +
         n +
         ",top=" +
         i
      );
   }



   $(document).on("click", ".share a, .down-list a, .openlink", function (e) {
      e.preventDefault();
      let link = $(this).attr("data-href");
      if (!link) {
         return;
      }
      sharePopup(link);
   });

   var myPlayerModal, isShownPlayer;

   if ($("#playerDialog").length) {
      //vlink

      myPlayerModal = new Modal($("#playerDialog"), {
         backdrop: "static",
         keyboard: false,
      });


      let vContainer = $(document).find("#videoInfo");

      $(".vlink").on("click", function (e) {
         e.preventDefault();
         let url = $(this).attr("data-url");
         apiClient(url, {}, function (data) {
            vContainer.find(".v-info #vTitle").html(data.vtitle);
            vContainer.find(".v-info .views").html(data.views);
            vContainer.find(".v-info .posted").html(data.published);
            let options = vContainer.find(".v-info .v-options");

            options.find("a.like").attr("data-id", data.id).attr("data-href", data.like);
            options.find("a.like").find(".title").html(data.like_count);

            options.find("a.unlike").attr("data-id", data.id).attr("data-href", data.unlike);
            options.find("a.downButton").attr("data-href", data.download);

            vContainer.find(".videoPlayer.ratio").attr("class", "videoPlayer ratio " + data.ratio);
            vContainer.find("#myPlayer").attr("data-action", data.action);
            vContainer.find("#myPlayer").attr("poster", data.poster);
            vContainer.find("source").first().attr("src", data.src).attr("type", data.type);
            vContainer.attr("class", "");
            vContainer.appendTo("#playerDialogContent");
            if (checkLikeUnlikes("likes")) {
               $(".v-options .like").addClass("active");
            } else if (checkLikeUnlikes("unlikes")) {
               $(".v-options .unlike").addClass("active");
            }
            myPlayerModal.show();

         });
      });

      $("#playerDialog").on("hidden.bs.modal", function () {
         isShownPlayer = false;
         let vidPlayer = $("#myPlayer")[0];
         vContainer.attr("class", "col-lg-6 g-0 mt-lg-2");
         vContainer.appendTo("#defaultPlayer");
         if (!vidPlayer.paused) {
            vidPlayer.pause();
         }
      });


      $("#playerDialog").on("shown.bs.modal", function () {
         isShownPlayer = true;
         let vidPlayer = $("#myPlayer")[0];
         played = false;
         vidPlayer.load();
         vidPlayer.play();
      });


   }

   if ($("#modalDialog").length) {

      let label;

      let modal = new Modal($("#modalDialog"), {
         backdrop: "static",
         keyboard: false,
      });

      if ($(".downButton").length) {
         $(".downButton").on("click", function (e) {
            e.preventDefault();
            let url = $(this).attr("data-href");
            let el = $(this).find('.title');
            el.text("Please Wait");
            $.ajax({
               url: url,
               dataType: "json",
               type: "POST",
               data: {
                  _token: $('meta[name="csrf-token"]').attr('content')
               }
            }).done(function (res) {
               if (res.status == true) {
                  el.text("Download");
                  window.location.replace(res.data.url);
               } else {
                  el.text("Retry");
                  console.log("Failed to request download");
               }

            }).fail(function () {
               console.error("Download failed");
               $(this).find('.title').text("Retry");
            });

         });

      }


      if ($(".sharebutton").length) {
         $(".sharebutton").on("click", function (e) {
            e.preventDefault();
            if (navigator.share !== undefined) {
               //Web share API
               navigator
                  .share({
                     title: "Share",
                     text: document.title,
                     url: window.location.href,
                  })
                  .then(function () {
                     console.info("Shared successfully.");
                  })
                  .catch(function (error) {
                     console.error("Error in sharing: ", error);
                  });
            } else {
               if (isShownPlayer) {
                  myPlayerModal.hide();
               }
               $("#shares").show();
               label = $(".share").attr("data-lang");
               $("#modalDialogLabel").html('<i class="fa fa-share" aria-hidden="true"></i> ' + label);
               modal.show();
            }
         });

      }

   }

   // End Video Page
});